<template>
  <div class="m-2">
    <arq-previews-page />
    <h1>Reconexión De Servicio</h1>
    <form class="w-50 lg:w-25 mx-auto my-4">
      <div class="card p-2">
        <div
          v-for="(f, index) in form"
          :key="index"
        >
          <label :for="f.key">
            {{ f.label }}
          </label>
          <div v-if="types.includes(f.type)">
            <div v-if="f.key.includes('identification')" />
            <div v-if="f.key.includes('phone')">
              <b-form-input
                :id="f.key"
                v-model="phone"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
              />
            </div>
            <div v-if="f.key.includes('email')">
              <b-form-input
                :id="f.key"
                v-model="email"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
              />
            </div>
            <b-form-input
              v-if="
                !f.key.includes('email') &&
                  !f.key.includes('phone') &&
                  !f.key.includes('identification')
              "
              :id="f.key"
              v-model="data[f.key]"
              :name="f.key"
              :type="f.type"
              :required="f.required"
              :disabled="f.disabled"
            />
          </div>
          <div v-if="f.type === 'select-contract'">
            <b-form-select
              :id="f.key"
              v-model.number="data[f.key]"
              value-field="idProducto"
              text-field="descservicio"
              :options="contratos"
              @input="getOptionServices(data[f.key])"
            />
          </div>
          <!-- :state="Boolean(data[f.key])" -->
          <b-form-file
            v-if="f.type === 'file'"
            :id="f.key"
            v-model="data[f.key]"
            :state="Boolean(data[f.key])"
            placeholder="Adjuntar documento..."
            drop-placeholder="Suelta el documento aqui..."
          />
          <div v-if="f.type === 'textarea'">
            <b-form-textarea
              :id="f.key"
              v-model="data[f.key]"
              rows="8"
            />
          </div>
        </div>
        <div class="col-12">
          <b-button
            :disabled="disabledButton"
            variant="primary"
            class="mt-3 d-flex text-center w-100 mr-auto ml-auto justify-content-center"
            @click="confirm(4000)"
          >
            <div v-if="loading">
              <b-spinner
                small
                class="mr-1"
                label="Small Spinner"
              />
            </div>
            Confirmar
          </b-button>
        </div>
      </div>
    </form>
    <b-modal
      id="modal-confirm-process"
      title="Tramite"
      ok-only
    >
      <div v-if="!response.message">
        <p class="my-4">
          <b-spinner
            variant="success"
            label="Spinning"
          />
          Enviando solicitud!
        </p>
      </div>
      <div v-else>
        <b-alert
          :variant="response.variant"
          show
        >
          {{ response.message }}
        </b-alert>
      </div>
    </b-modal>
    <b-modal
      id="modal-actualizar-datos-personales"
      title="Actualizar datos personales"
      hide-footer
    >
      <!-- ok-title="Confirmar"
      cancel-title="Cancelar"
      @ok="handleOk" -->
      <div v-if="!responseUpdatePersonal.message">
        <b-alert
          variant="danger"
          show
        >
          Necesita actualizar los datos personales
        </b-alert>
        <form
          ref="formActualizarDatosPersonales"
          @submit.stop.prevent="handleSubmit"
        >
          <!-- <b-form-group
            label="Identifiacion"
            label-for="identifiacion-input"
            invalid-feedback="Identificacion es requerida"
            :state="identificacionState"
          >
            <b-form-input
              id="identifiacion-input"
              v-model="dataPersonal.identificacion"
              required
              type="number"
              :state="identificacionState"
            />
          </b-form-group> -->
          <b-form-group
            label="Telefono"
            label-for="phone-input"
            invalid-feedback="Telefono es requerido"
            :state="phoneState"
          >
            <b-form-input
              id="phone-input"
              v-model="dataPersonal.celularespo"
              max-length="10"
              min-length="10"
              required
              type="number"
              :state="phoneState"
            />
          </b-form-group>
          <b-form-group
            label="Correo"
            label-for="email-input"
            invalid-feedback="Email es requerido"
            :state="emailState"
          >
            <b-form-input
              id="email-input"
              v-model="dataPersonal.emailresposerv"
              required
              type="email"
              :state="emailState"
            /></b-form-group>
        </form>
        <button
          class="bg-primary text-white m-auto mr-auto btn"
          style="position: relative; margin-left: 12em !important;"
          @click.prevent="handleOk"
        >
          Confirmar
        </button>
      </div>
      <div v-else>
        <b-alert
          :variant="responseUpdatePersonal.variant"
          show
        >
          a {{ responseUpdatePersonal.message }}
        </b-alert>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BSpinner } from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      imageSrc: '',
      fileType: '',
      fileName: '',
      phone: '',
      email: '',
      loading: false,
      form: [
        /*  {
          label: 'Identificación',
          key: 'identification',
          type: 'number',
          required: true,
        }, */
        {
          label: 'Contrato',
          key: 'contrato',
          type: 'select-contract',
          required: true,
        },
        {
          label: 'Celular',
          key: 'phone',
          type: 'number',
          disabled: true,
          required: true,
        },
        {
          label: 'Correo',
          key: 'email',
          type: 'text',
          disabled: true,
          required: true,
        },
        {
          label: 'Observacion',
          key: 'observacion',
          type: 'textarea',
          required: true,
        },
        {
          label: 'Seleccione un archivo',
          key: 'file',
          type: 'file',
          disabled: true,
          required: true,
        },
      ],
      data: {},
      types: [
        'text',
        'number',
        'email',
        'password',
        'search',
        'url',
        'tel',
        'date',
        'time',
        'range',
        'color',
      ],
      optionsServices: [],
      idService: 0,
      disabledButton: false,
      process: {},
      responseUpdatePersonal: {},
      phoneState: false,
      emailState: false,
      response: {},
      dataPersonal: {},
    }
  },
  watch: {
    'data.file': function (el) {
      this.data.fileName = el.name
      this.data.fileType = el.type
      this.getBase64(el)
    },
  },
  computed: {
    ...mapState('client', ['contratos']),
    observer() {
      const { contrato } = this.data
      if (contrato) {
        this.getOptionServices(contrato)
      }
      return null
    },
  },
  methods: {
    getBase64(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      const self = this
      reader.onload = function () {
        const r = reader.result
        //  console.log(r)
        //  console.log('self', self)
        const base = r.split(',')[1]
        self.data.fileBase64 = base
      }
    },
    getContract(id) {
      this.contratos = []
      axios.get(`/client/contracts/${1}/${id}`).then(res => {
        this.contratos = res.data.list
      })
    },
    getOptionServices(contrato) {
      this.phone = ''
      this.email = ''
      axios
        .get(`/app/services/fromContract?idContract=${contrato}`)
        .then(res => {
          if (res.data.code === 0) {
            this.optionsServices = res.data.list
            this.idService = res.data.object.idProductoDefault
            this.phone = res.data.object.celularespo
            this.email = res.data.object.emailresposerv
          } else {
            this.optionsServices = []
          }
        })
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      let validate = false
      if (!this.dataPersonal.celularespo && !this.dataPersonal.emailresposer) {
        validate = this.checkFormValidity()
        if (!validate) {
          // this.identificacionState = true
          // console.log('blos')
          return false
        }
      }

      // Prevent modal from closing
      validate = this.checkFormValidity()
      this.phoneState = true
      this.emailState = true

      this.dataPersonal.identificacion = this.$store.state.client.client.identificacion
      axios
        .post('/app/person/update', this.dataPersonal)
        .then(res => {
          if (res.data.code === 0) {
            this.responseUpdatePersonal.message = 'Sus datos han sido actualizados exitosamente.'
            this.responseUpdatePersonal.variant = 'success'
            this.data = {}
          } else {
            this.responseUpdatePersonal.message = 'Error al intentar actualizar los datos.'
            this.responseUpdatePersonal.variant = 'danger'
          }
        })
        .finally(() => {
          this.dataPersonal = {}
        })
      this.$nextTick(() => {
        this.$bvModal.hide('modal-actualizar-datos-personales')
      })
      return true
      // Trigger submit handler
    },
    checkFormValidity() {
      const valid = this.$refs.formActualizarDatosPersonales.checkValidity()
      return valid
    },
    openActualizacionDatos() {
      this.$bvModal.show('modal-actualizar-datos-personales')
    },
    confirm() {
      this.response = {}
      if (
        this.phone === ''
        || this.phone === null
        || !this.phone
        || !this.email
        || this.email === ''
        || this.email === null
      ) {
        this.openActualizacionDatos()
        return
      }
      this.loading = true
      this.disabledButton = true
      this.data.identification = this.$store.state.client.client.identificacion
      this.data.idService = this.idService
      this.data.phone = this.phone
      this.data.email = this.email
      this.$bvModal.show('modal-confirm-process')
      this.sendGetProcedure(this.data)
        .then(res => {
          if (res.data.code === 0) {
            this.response = res.data
            this.response.message = res.data.msg || 'Su tramite ha sido enviado'
            this.response.variant = 'success'
            this.data = {}
          } else {
            this.response = res.data
            this.response.message = 'Error al procesar su tramite'
            this.response.variant = 'danger'
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
          this.disabledButton = false
        })
    },

    async sendGetProcedure(formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/app/procedure/reconexionServicio', formData)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}
</script>
